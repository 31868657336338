import { Center } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  SettingsPageContainer,
} from "../../../../components";
import { CurrentUserFragment } from "../../../graphql";
import CoachingDemoForm from "./CoachingDemoForm";
import useCoachingTask from "./useCoachingTask";
import useUserCanAccessCoaching from "./useUserCanAccessCoaching";

interface CoachingSettingsProps {
  currentUser: CurrentUserFragment;
}

const CoachingSettings: React.FC<CoachingSettingsProps> = ({ currentUser }) => {
  const {
    taskDefinition,
    taskRun,
    updateCoachingTaskDefinition,
    runPreviewCoaching,
  } = useCoachingTask();
  const taskDefinitionId = taskDefinition.taskDefinition?.id;

  return (
    <SettingsPageContainer
      maxW={660}
      heading="Coaching preferences"
      subHeading="Customize the content and delivery of interview coaching tips to your interviewers."
    >
      {taskDefinition.loading && (
        <Center>
          <LoadingIndicator />
        </Center>
      )}
      {!taskDefinition.loading && taskDefinition.error && (
        <Alert
          status="error"
          title="Error fetching coaching task"
          description={taskDefinition.error.message}
        />
      )}
      {!taskDefinition.loading &&
        !taskDefinition.error &&
        taskDefinition.taskDefinition &&
        taskDefinitionId &&
        taskDefinition.parsedConfiguration && (
          <CoachingDemoForm
            configuration={taskDefinition.parsedConfiguration}
            taskRunResult={taskRun}
            onPreview={(previewUserId, config) =>
              runPreviewCoaching.mutation({
                interviewerId: previewUserId,
                taskDefinitionId,
                config,
              })
            }
            onSave={(config) => {
              updateCoachingTaskDefinition.mutation({
                id: taskDefinitionId,
                config,
              });
            }}
            saveLoading={updateCoachingTaskDefinition.loading}
          />
        )}
    </SettingsPageContainer>
  );
};

// TODO: Move this wrapper and coaching access logic to access map
const CoachingSettingsWrapper: React.FC<CoachingSettingsProps> = (
  props: CoachingSettingsProps
) => {
  const { currentUser } = props;
  const userCanAccessCoaching = useUserCanAccessCoaching(currentUser);

  if (!userCanAccessCoaching) {
    return <Navigate to="/not-found" replace />;
  }

  return <CoachingSettings {...props} />;
};

export default CoachingSettingsWrapper;
