import { CurrentUserFragment, UserRolePermission } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";

const useUserCanAccessCoaching = (
  currentUser: CurrentUserFragment
): boolean => {
  const coachingEnabled = useFeatureFlag("coaching:v1");
  const userPermitted = currentUser.userRole?.permissions?.includes(
    UserRolePermission.EditAiTaskConfiguration
  );
  return !!(coachingEnabled && userPermitted);
};

export default useUserCanAccessCoaching;
