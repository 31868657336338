import { TaskDefinitionFragment, TaskRunFragment } from "../../../graphql";
import { CoachingConfiguration } from "./types";

export const parseCoachingConfiguration = (
  taskDefinition: TaskDefinitionFragment | null
): CoachingConfiguration | null => {
  if (!taskDefinition) {
    return null;
  }
  try {
    const c = JSON.parse(taskDefinition.configuration);
    return {
      customInstructions: c.custom_instructions || "",
      sendEmail: c.send_email || false,
    };
  } catch (e) {
    return null;
  }
};

export const stringifyCoachingConfiguration = (
  configuration?: CoachingConfiguration
): string => {
  return JSON.stringify({
    // eslint-disable-next-line camelcase
    custom_instructions: configuration?.customInstructions || "",
    // eslint-disable-next-line camelcase
    send_email: configuration?.sendEmail || false,
  });
};

export const getMarkdown = (taskRun: TaskRunFragment): string => {
  if (!taskRun) {
    return "";
  }
  try {
    const output = JSON.parse(taskRun.output);
    return output?.preview_markdown || "";
  } catch (e) {
    return "";
  }
};
