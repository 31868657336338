import { useCallback, useEffect } from "react";

import { useToast } from "../../../../components";
import {
  CreateOrUpdateTaskDefinitionMutation,
  TaskDefinitionFragment,
  TaskDefinitionType,
  TaskRunFragment,
  TaskRunStatus,
  useCreateOrUpdateTaskDefinitionMutation,
  usePreviewTaskRunMutation,
  useTaskDefinitionsQuery,
  useTaskRunQuery,
} from "../../../graphql";
import { CoachingConfiguration } from "./types";
import {
  parseCoachingConfiguration,
  stringifyCoachingConfiguration,
} from "./utils";

const NEW_CONFIGURATION = {
  customInstructions: "",
  sendEmail: false,
};

export type CreateCoachingTaskDefinitionProps = {
  config?: CoachingConfiguration;
  callback?: (data: CreateOrUpdateTaskDefinitionMutation) => void;
};

export type UpdateCoachingTaskDefinitionProps = {
  id: string;
  config: CoachingConfiguration;
};

export type PreviewCoachingTaskRunProps = {
  taskDefinitionId: string;
  interviewerId: string;
  config: CoachingConfiguration;
};

export type TaskRunResult = {
  taskRun: TaskRunFragment | null;
  loading: boolean;
  error: Error | undefined;
};

export type TaskDefinitionResult = {
  taskDefinition: TaskDefinitionFragment | null;
  loading: boolean;
  error: Error | undefined;
  parsedConfiguration: CoachingConfiguration | null;
};

type CoachingTaskReturn = {
  taskDefinition: TaskDefinitionResult;
  taskRun: TaskRunResult;
  updateCoachingTaskDefinition: {
    mutation: (props: UpdateCoachingTaskDefinitionProps) => void;
    loading: boolean;
    error: Error | undefined;
  };
  runPreviewCoaching: {
    mutation: (props: PreviewCoachingTaskRunProps) => void;
    loading: boolean;
    error: Error | undefined;
  };
};

const COACHING_TASK_TYPE = TaskDefinitionType.Coaching;

const useCoachingTask: () => CoachingTaskReturn = () => {
  const toast = useToast();

  // Function to create a new task definition
  const [createOrUpdateTaskDefinition, createOrUpdateTaskDefinitionMutation] =
    useCreateOrUpdateTaskDefinitionMutation();
  const createCoachingTaskDefinition = useCallback(
    (callback?: (data: CreateOrUpdateTaskDefinitionMutation) => void) => {
      createOrUpdateTaskDefinition({
        variables: {
          type: COACHING_TASK_TYPE,
          configurationJson: stringifyCoachingConfiguration(NEW_CONFIGURATION),
        },
        onCompleted: (data) => {
          const { createOrUpdateTaskDefinition: newTaskDefinition } = data;
          if (newTaskDefinition) {
            callback?.(data);
          }
        },
      });
    },
    [createOrUpdateTaskDefinition]
  );

  // Function to update coaching task definition - requires id and configuration
  const updateCoachingTaskDefinition = useCallback(
    (props: UpdateCoachingTaskDefinitionProps) => {
      const { id, config } = props;
      createOrUpdateTaskDefinition({
        variables: {
          id,
          type: COACHING_TASK_TYPE,
          configurationJson: stringifyCoachingConfiguration(config),
        },
        onCompleted: (data) => {
          toast({
            title: "Success",
            description: "Coaching task updated.",
            status: "success",
          });
        },
        onError: () => {
          toast({
            title: "Error",
            description: "There was a problem updating the coaching task.",
            status: "error",
          });
        },
      });
    },
    [createOrUpdateTaskDefinition, toast]
  );

  // Get task definitions and create if none exists
  const taskDefinitionsQuery = useTaskDefinitionsQuery({
    variables: {
      type: COACHING_TASK_TYPE,
    },
    onCompleted: (data) => {
      const { taskDefinitions } = data;
      if (!taskDefinitions) return;

      if (taskDefinitions.length === 0) {
        // Create a new task definition if none exists
        createCoachingTaskDefinition(() => {
          taskDefinitionsQuery.refetch();
        });
      }
    },
  });

  const taskDefinitions = taskDefinitionsQuery.data?.taskDefinitions;
  const taskDefinition =
    taskDefinitions && taskDefinitions.length > 0 ? taskDefinitions[0] : null;

  // Function to run the coaching task preview
  const [previewTask, previewTaskMutation] = usePreviewTaskRunMutation();
  const runPreviewCoaching = useCallback(
    (props: PreviewCoachingTaskRunProps): void => {
      const { taskDefinitionId, interviewerId, config } = props;
      previewTask({
        variables: {
          id: taskDefinitionId,
          taskInputJson: JSON.stringify({
            // eslint-disable-next-line camelcase
            interviewer_id: interviewerId,
          }),
          runConfigurationJson: stringifyCoachingConfiguration(config),
        },
      });
    },
    [previewTask]
  );

  const taskRunId = previewTaskMutation.data?.previewTaskRun?.taskRun?.id;
  const taskRunQuery = useTaskRunQuery({
    variables: {
      id: taskRunId || "",
    },
    pollInterval: 3000,
    skip: !taskRunId,
  });

  useEffect(() => {
    const taskStatus = taskRunQuery.data?.taskRun?.status;
    if (
      taskRunQuery.error ||
      (taskStatus &&
        [
          TaskRunStatus.Completed,
          TaskRunStatus.CompletedPreview,
          TaskRunStatus.Failed,
        ].includes(taskStatus))
    ) {
      taskRunQuery.stopPolling();
    }
  }, [
    taskRunQuery.data?.taskRun?.status,
    taskRunQuery.error,
    taskRunQuery.stopPolling,
  ]);

  return {
    taskDefinition: {
      loading: taskDefinitionsQuery.loading,
      error: taskDefinitionsQuery.error,
      taskDefinition: taskDefinition || null,
      parsedConfiguration: parseCoachingConfiguration(taskDefinition),
    },
    taskRun: {
      loading: taskRunQuery.loading,
      error: taskRunQuery.error,
      taskRun: taskRunQuery.data?.taskRun || null,
    },
    updateCoachingTaskDefinition: {
      mutation: updateCoachingTaskDefinition,
      loading: createOrUpdateTaskDefinitionMutation.loading,
      error: createOrUpdateTaskDefinitionMutation.error,
    },
    runPreviewCoaching: {
      mutation: runPreviewCoaching,
      loading: previewTaskMutation.loading,
      error: previewTaskMutation.error,
    },
  };
};

export default useCoachingTask;
