import { Center, Flex, Text } from "@chakra-ui/react";
import React from "react";

import {
  Alert,
  LoadingIndicator,
  MarkdownOutput,
} from "../../../../components";
import Modal from "../../../components/Modal/Modal";
import { TaskRunStatus } from "../../../graphql";
import { TaskRunResult } from "./useCoachingTask";
import { getMarkdown } from "./utils";

type CoachingPreviewModalProps = {
  taskRunResult: TaskRunResult;
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
};

const CoachingPreviewModal: React.FC<CoachingPreviewModalProps> = ({
  taskRunResult,
  isOpen,
  onClose,
}) => {
  const { loading, error, taskRun } = taskRunResult;

  const showLoader =
    taskRun &&
    (!taskRun?.status || taskRun.status === TaskRunStatus.InProgress);

  return (
    <Modal
      isOpen={isOpen}
      isLoading={loading}
      headerText="Preview coaching tips"
      completeButtonText="Done"
      onComplete={onClose}
      onClose={onClose}
      modalProps={{
        size: "xl",
        scrollBehavior: "inside",
      }}
    >
      <Center w="100%">
        {showLoader && (
          <Flex flexDir="column">
            <LoadingIndicator my="4" />
            <Text color="gray.500" fontSize="17px" fontWeight="500">
              {taskRun?.statusMessage || "Loading..."}
            </Text>
          </Flex>
        )}
        {!loading && error && (
          <Alert
            status="error"
            title="Error"
            description="An error occurred while fetching the data."
          />
        )}
        {!loading &&
          !error &&
          taskRun?.status &&
          [TaskRunStatus.Completed, TaskRunStatus.CompletedPreview].includes(
            taskRun.status
          ) && (
            <MarkdownOutput
              markdown={getMarkdown(taskRun)}
              onClickTimestamp={() => null}
              w="100%"
              bg="gray.50"
            />
          )}
      </Center>
    </Modal>
  );
};

export default CoachingPreviewModal;
